/*!
 RowReorder 1.2.8
 2015-2020 SpryMedia Ltd - datatables.net/license
*/
(function(e){"function"===typeof define&&define.amd?define(["jquery","datatables.net"],function(f){return e(f,window,document)}):"object"===typeof exports?module.exports=function(f,g){f||(f=window);if(!g||!g.fn.dataTable)g=require("datatables.net")(f,g).$;return e(g,f,f.document)}:e(jQuery,window,document)})(function(e,f,g,o){var i=e.fn.dataTable,j=function(d,c){if(!i.versionCheck||!i.versionCheck("1.10.8"))throw"DataTables RowReorder requires DataTables 1.10.8 or newer";this.c=e.extend(!0,{},i.defaults.rowReorder,
j.defaults,c);this.s={bodyTop:null,dt:new i.Api(d),getDataFn:i.ext.oApi._fnGetObjectDataFn(this.c.dataSrc),middles:null,scroll:{},scrollInterval:null,setDataFn:i.ext.oApi._fnSetObjectDataFn(this.c.dataSrc),start:{top:0,left:0,offsetTop:0,offsetLeft:0,nodes:[]},windowHeight:0,documentOuterHeight:0,domCloneOuterHeight:0};this.dom={clone:null,dtScroll:e("div.dataTables_scrollBody",this.s.dt.table().container())};var b=this.s.dt.settings()[0],a=b.rowreorder;if(a)return a;this.dom.dtScroll.length||(this.dom.dtScroll=
e(this.s.dt.table().container(),"tbody"));b.rowreorder=this;this._constructor()};e.extend(j.prototype,{_constructor:function(){var d=this,c=this.s.dt,b=e(c.table().node());"static"===b.css("position")&&b.css("position","relative");e(c.table().container()).on("mousedown.rowReorder touchstart.rowReorder",this.c.selector,function(a){if(d.c.enable){if(e(a.target).is(d.c.excludedChildren))return!0;var b=e(this).closest("tr"),h=c.row(b);if(h.any())return d._emitEvent("pre-row-reorder",{node:h.node(),index:h.index()}),
d._mouseDown(a,b),!1}});c.on("destroy.rowReorder",function(){e(c.table().container()).off(".rowReorder");c.off(".rowReorder")})},_cachePositions:function(){var d=this.s.dt,c=e(d.table().node()).find("thead").outerHeight(),b=e.unique(d.rows({page:"current"}).nodes().toArray()),b=e.map(b,function(b){var d=e(b).position().top-c;return(d+d+e(b).outerHeight())/2});this.s.middles=b;this.s.bodyTop=e(d.table().body()).offset().top;this.s.windowHeight=e(f).height();this.s.documentOuterHeight=e(g).outerHeight()},
_clone:function(d){var c=e(this.s.dt.table().node().cloneNode(!1)).addClass("dt-rowReorder-float").append("<tbody/>").append(d.clone(!1)),b=d.outerWidth(),a=d.outerHeight(),g=d.children().map(function(){return e(this).width()});c.width(b).height(a).find("tr").children().each(function(b){this.style.width=g[b]+"px"});c.appendTo("body");this.dom.clone=c;this.s.domCloneOuterHeight=c.outerHeight()},_clonePosition:function(d){var c=this.s.start,b=this._eventToPage(d,"Y")-c.top,d=this._eventToPage(d,"X")-
c.left,a=this.c.snapX,b=b+c.offsetTop,c=!0===a?c.offsetLeft:"number"===typeof a?c.offsetLeft+a:d+c.offsetLeft;0>b?b=0:b+this.s.domCloneOuterHeight>this.s.documentOuterHeight&&(b=this.s.documentOuterHeight-this.s.domCloneOuterHeight);this.dom.clone.css({top:b,left:c})},_emitEvent:function(d,c){this.s.dt.iterator("table",function(b){e(b.nTable).triggerHandler(d+".dt",c)})},_eventToPage:function(d,c){return-1!==d.type.indexOf("touch")?d.originalEvent.touches[0]["page"+c]:d["page"+c]},_mouseDown:function(d,
c){var b=this,a=this.s.dt,m=this.s.start,h=c.offset();m.top=this._eventToPage(d,"Y");m.left=this._eventToPage(d,"X");m.offsetTop=h.top;m.offsetLeft=h.left;m.nodes=e.unique(a.rows({page:"current"}).nodes().toArray());this._cachePositions();this._clone(c);this._clonePosition(d);this.dom.target=c;c.addClass("dt-rowReorder-moving");e(g).on("mouseup.rowReorder touchend.rowReorder",function(a){b._mouseUp(a)}).on("mousemove.rowReorder touchmove.rowReorder",function(a){b._mouseMove(a)});e(f).width()===e(g).width()&&
e(g.body).addClass("dt-rowReorder-noOverflow");a=this.dom.dtScroll;this.s.scroll={windowHeight:e(f).height(),windowWidth:e(f).width(),dtTop:a.length?a.offset().top:null,dtLeft:a.length?a.offset().left:null,dtHeight:a.length?a.outerHeight():null,dtWidth:a.length?a.outerWidth():null}},_mouseMove:function(d){this._clonePosition(d);for(var c=this._eventToPage(d,"Y")-this.s.bodyTop,b=this.s.middles,a=null,g=this.s.dt,h=0,f=b.length;h<f;h++)if(c<b[h]){a=h;break}null===a&&(a=b.length);if(null===this.s.lastInsert||
this.s.lastInsert!==a)c=e.unique(g.rows({page:"current"}).nodes().toArray()),a>this.s.lastInsert?this.dom.target.insertAfter(c[a-1]):this.dom.target.insertBefore(c[a]),this._cachePositions(),this.s.lastInsert=a;this._shiftScroll(d)},_mouseUp:function(d){var c=this,b=this.s.dt,a,f,h=this.c.dataSrc;this.dom.clone.remove();this.dom.clone=null;this.dom.target.removeClass("dt-rowReorder-moving");e(g).off(".rowReorder");e(g.body).removeClass("dt-rowReorder-noOverflow");clearInterval(this.s.scrollInterval);
this.s.scrollInterval=null;var n=this.s.start.nodes,l=e.unique(b.rows({page:"current"}).nodes().toArray()),j={},i=[],k=[],p=this.s.getDataFn,o=this.s.setDataFn;a=0;for(f=n.length;a<f;a++)if(n[a]!==l[a]){var q=b.row(l[a]).id(),u=b.row(l[a]).data(),r=b.row(n[a]).data();q&&(j[q]=p(r));i.push({node:l[a],oldData:p(u),newData:p(r),newPosition:a,oldPosition:e.inArray(l[a],n)});k.push(l[a])}var s=[i,{dataSrc:h,nodes:k,values:j,triggerRow:b.row(this.dom.target),originalEvent:d}];this._emitEvent("row-reorder",
s);var t=function(){if(c.c.update){a=0;for(f=i.length;a<f;a++){var d=b.row(i[a].node).data();o(d,i[a].newData);b.columns().every(function(){this.dataSrc()===h&&b.cell(i[a].node,this.index()).invalidate("data")})}c._emitEvent("row-reordered",s);b.draw(!1)}};this.c.editor?(this.c.enable=!1,this.c.editor.edit(k,!1,e.extend({submit:"changed"},this.c.formOptions)).multiSet(h,j).one("preSubmitCancelled.rowReorder",function(){c.c.enable=!0;c.c.editor.off(".rowReorder");b.draw(!1)}).one("submitUnsuccessful.rowReorder",
function(){b.draw(!1)}).one("submitSuccess.rowReorder",function(){t()}).one("submitComplete",function(){c.c.enable=!0;c.c.editor.off(".rowReorder")}).submit()):t()},_shiftScroll:function(d){var c=this,b=this.s.scroll,a=!1,i=d.pageY-g.body.scrollTop,h,j;i<e(f).scrollTop()+65?h=-5:i>b.windowHeight+e(f).scrollTop()-65&&(h=5);null!==b.dtTop&&d.pageY<b.dtTop+65?j=-5:null!==b.dtTop&&d.pageY>b.dtTop+b.dtHeight-65&&(j=5);h||j?(b.windowVert=h,b.dtVert=j,a=!0):this.s.scrollInterval&&(clearInterval(this.s.scrollInterval),
this.s.scrollInterval=null);!this.s.scrollInterval&&a&&(this.s.scrollInterval=setInterval(function(){if(b.windowVert){var a=e(g).scrollTop();e(g).scrollTop(a+b.windowVert);if(a!==e(g).scrollTop()){a=parseFloat(c.dom.clone.css("top"));c.dom.clone.css("top",a+b.windowVert)}}if(b.dtVert){a=c.dom.dtScroll[0];if(b.dtVert)a.scrollTop=a.scrollTop+b.dtVert}},20))}});j.defaults={dataSrc:0,editor:null,enable:!0,formOptions:{},selector:"td:first-child",snapX:!1,update:!0,excludedChildren:"a"};var k=e.fn.dataTable.Api;
k.register("rowReorder()",function(){return this});k.register("rowReorder.enable()",function(d){d===o&&(d=!0);return this.iterator("table",function(c){c.rowreorder&&(c.rowreorder.c.enable=d)})});k.register("rowReorder.disable()",function(){return this.iterator("table",function(d){d.rowreorder&&(d.rowreorder.c.enable=!1)})});j.version="1.2.8";e.fn.dataTable.RowReorder=j;e.fn.DataTable.RowReorder=j;e(g).on("init.dt.dtr",function(d,c){if("dt"===d.namespace){var b=c.oInit.rowReorder,a=i.defaults.rowReorder;
if(b||a)a=e.extend({},b,a),!1!==b&&new j(c,a)}});return j});

var InifiniteScroll = (function(resourceUrl){
	
	var obj = {};

	var url = resourceUrl; // Endpoint URL for the data resource
	var currentPage = 0; // Current page index
	var lastPageReached = false; // When there are no more data to load, make this to true
	var requestLoaded = true; // When data is done loading, make this to true. Another request won't be sent until this becomes true
	
	var finishedText = "<div class='card px-4 py-3 me-lg-3 shadow text-muted text-center'>You've reached the end of the list</div><div class='card px-4 py-3 shadow text-muted text-center cursor-pointer' id='ScrollTop'>up</div>";
	var $loader = $("#infinite-loader");
	var onCompleteCallback = function () {};
	/**
	 * Load data when scorlled
	 */
	function onScroll(){
		$(window).scroll(function () {

			// If the last page is not reached
			if(!lastPageReached)
			{
				if (requestLoaded && $(window).scrollTop() >= $(document).height() - $(window).height() - 100) {
					requestLoaded = false;
					makeRequest();
					currentPage++;
				}
			}
		});
	}

	function showLoader()
	{
		$loader.removeClass("d-none");
		$loader.addClass("d-flex");
	}

	function hideLoader()
	{
		$loader.removeClass("d-flex");
		$loader.addClass("d-none");
	}

	function finished()
	{
		$('#infinite-loader').html(finishedText);
	}

	function onRequestComplete(data)
	{
		if(data.trim() == '')
		{
			finished();
		}
		else
		{
			requestLoaded = true;
			hideLoader();
			$(data).insertBefore('#infinite-loader');
			onCompleteCallback();
		}
	}

	/**
	 * Make AJAX request
	 */ 
	var makeRequest = function()
	{
		var $pageDetails = {"page": currentPage};
		showLoader();
		$.ajax({
		    url : url,
		    type: "GET",
			data : "page="+currentPage,
		    success: function(data, textStatus, jqXHR)
		    {
		    	onRequestComplete(data);
		    },
		    error: function (jqXHR, textStatus, errorThrown)
		    {
		 
		    }
		});
	}
	
	/**
	 * Initialize the object
	 */
	obj.init = function(){
		onScroll();
	}

	obj.setCurrentPage = function(curPage)
	{
		currentPage = curPage;
	}

	obj.setUrl = function(arg_url)
	{
		url = arg_url;
	}

	obj.setOnCompleteCallback = function(callback)
	{
		onCompleteCallback = callback;
	}
	
	return obj;
});
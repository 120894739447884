var counterText = 1;
var answers = new Array();
function addImageField(divID){

	var newdiv = document.createElement('div');
	newdiv.innerHTML = "<div class='form-group'><div class='modal_label'>Image"+counterText+"</div><input name='images[]' type='file'></div>";
	counterText++;

	document.getElementById(divID).appendChild(newdiv);
}
function initateCaptivate(url,index)
{

	/**Play Tutorial screen**/
	$(window).bind('load resize',function(){

		var $tutorialImage 	= $('.tutorialImage').eq(index);
		var overlayClass	= "tutorialOverlay";
		var overlayId		= "tutorialOverlay"+index;
		var palyBttnClass	= "playButton";
		var palyBttnId		= "playButton"+index;

		$('#'+overlayId).remove();
		$('#'+palyBttnId).remove();

		var tuLeft 		= parseFloat($tutorialImage.offset().left);
		var tuTop 		= parseFloat($tutorialImage.offset().top);
		var tuWidth 	= parseFloat($tutorialImage.outerWidth());
		var tuHeight 	= parseFloat($tutorialImage.outerHeight());

		var $overlay 	= $("<div class='"+overlayClass+"' id='"+overlayId+"'></div>");
		var $playButtn 	= $("<div class='"+palyBttnClass+"' data-url='"+url+"' id='"+palyBttnId+"'></div>");

	/*	$overlay.css({
			'width' : tuWidth,
			'height' : tuHeight,
			'position': 'absolute'
		});

		$overlay.offset({
			'left' : tuLeft,
			'top' : tuTop,
		});*/
		$tutorialImage.parent().addClass("position-relative");
		$tutorialImage.parent().append($overlay); // Append overlay to the parent of the image
		$tutorialImage.parent().append($playButtn); // Append play button to the overlay

		$playButtn.offset({
			'left' : tuLeft + (tuWidth - parseFloat($playButtn.outerWidth()))/2,
			'top' : tuTop + (tuHeight - parseFloat($playButtn.outerHeight()))/2
		});
	});

	$('body').on('click','.playButton',function(){
		 wnd = window.open($(this).data("url"), 'Captivate Content', 'height=675,width=900,left=100');
	});
}

function setAnswersForm()
{
	var none_null = false;
	// Check if the array contains only null values
	for(var i=0;i<answers.length;i++)
	{
		if(answers[i] !== null && answers[i] !== "")
		{
			none_null = true;
			break;
		}
	}
	
	// If the array contains any none value
	if(none_null)
		$("#answers").attr("value",answers.join("/"));
	else
		$("#answers").attr("value","");
}
function addMultipleMcqFields()
{
	// Get the number of children to decide the label name
	var c_amount = $("#mcqFields").children().length;

	var task 	= "<div class='mcq_field form-group'><div class='modal_label'>Task"+(c_amount/2+1)+"</div><input id='multiMcqTask' name='mcq_tasks[]' value='' type='text' placeholder='Enter Task'></div>";

	var answers = "<div id='taskAnswers' class='mcq_field'><div class='form-group'><div class='modal_label'>Answers"+(c_amount/2+1)+"</div><textarea rows='6' name='mcq_answers[]' id='multiMcqAns' placeholder='Seperate two answers with / symbol'></textarea></div></div>";

	$("#mcqFields").append(task,answers);
}
function removeMultipleMcqFields()
{
	$("#mcqFields").children().remove();
}

$(window).resize(function(){
	setRankBar();

	// When the screen size is in mobile view, hide the slider
	if($(".slider-outer").length > 0 )
	{
		if($(window).width() <= 320)
			$(".slider-outer").css("display","none");
		else
			$(".slider-outer").css("display","block");
	}
});
function openGuideWindow( link )
{
	/* $('<div/>').dialog2({
		title: "Welcome",
		content: link,
		id: "firtTimeUserGuide",
		buttons: false,
		removeOnClose: true
	}); */
}
$(window).on('load', function() {
	initBlanks(); /*Moved to window load event because if a image is loaded inside the task, the droppable MUST load after image loading, Other wise doesn't work*/
});

$(document).ready(function(e) {
	setRankBar();
	$(".partialSelect").prop("indeterminate", true);
	$('.progress .bar').progressbar({
		transition_delay: 300,
		refresh_speed: 50,
		display_text: 2,
		use_percentage: true
	});

	/* When table length is changed, add dialog2 to all the newly displayed items */
	$(".dataTables_length select").change(function(){
		$(document).controls();
	});
	$(".dataTables_paginate a").click(function(){
		$(document).controls();
	});

	$(document).on('click',".checkbox_answer", function(){
		var question_id = $(this).attr("name");
		answers[question_id] = $(this).data("index");

		$("#answers").attr("value",answers.join("/"));
	});

	$(document).on('click',".checkbox_answer_label", function(){
		var question_id = $(this).data("question");
		answers[question_id] = $(this).data("index");

		$("#answers").attr("value",answers.join("/"));
	});

	$(document).controls();/* Load doialog2 */

	if($('#answers').length > 0 && $("#answers").attr("value") != "")
		answers = $("#answers").attr("value").split("/");

	$("#addMCQ").click(function(){
		addMultipleMcqFields();
	});
	/* ============================== SELECT CORRECT IMAGE QUESTION ============================== */
	$(".addAsAnswer").click(function(){

		var answers 	= $("#answers").attr("value");

		if($(this).attr("type") == "radio")
			answers = $(this).data("index"); // Radio buttons always have one selection as answer
		else
		{
			var id = parseInt($(this).data("index"));

			if($(this).is(':checked'))
			{
				if(answers == "")
					answers = ""+id;
				else
					answers += "/" + id;
			}
			else
			{
				answers = answers.replace("/"+id,"");
				answers = answers.replace(id+"/","");
				answers = answers.replace(id,"");
			}
		}

		var string_check = answers;

		string_check = string_check.replace(/\//g,""); // Replace all "/" characters

		if(string_check == "")
			$("#answers").attr("value",null);
		else
			$("#answers").attr("value",answers);
	});
	/* ============================== ANSWERING MULTIPLE MCQ QUESTION ============================== */
	$(document).on('click', ".multipleMcq", function(){

		var answers 	= $("#answers").val();
		var id 			= parseInt($(this).data("id")); // Answer id
		var question	= parseInt($(this).data("question")); // Question id
		question		-= 1;

		const isSingleSelect = $(this).attr('type') === 'radio';


		var q_answers = answers.split("::");

		if(q_answers[question] === undefined)
			q_answers[question] = "";

		var parentId = $(this).parents(".answersContainer").attr("id");
		var answerVal = $("#"+parentId+" .multipleMcq").index($(this));

		// If Single Select
		if (isSingleSelect) {
			q_answers[question] = answerVal;
		} else {

			// If Multi Select
			if ($(this).is(':checked')) {
				if (q_answers[question] == "")
					q_answers[question] = answerVal;
				else
					q_answers[question] += "/" + answerVal;
			} else {
				q_answers[question] = q_answers[question].replace("/" + answerVal, "");
				q_answers[question] = q_answers[question].replace(answerVal + "/", "");
				q_answers[question] = q_answers[question].replace(answerVal, "");
			}
		}

		var string_check = q_answers.join("::");

		string_check = string_check.replace(/\//g,""); // Replace all "/" characters
		string_check = string_check.replace(/::/g,""); // Replace all "::" characters

		if(string_check == "")
			$("#answers").attr("value",null);
		else
			$("#answers").attr("value",q_answers.join("::"));
	});
	/*REMOVE FILE UPLOAD FILE IF A FILE IS NOT UPLOADED (To avoid uploading junk content to the cloud storage bucket)*/
	$("form").submit(function(){
		var fileField = $(this).find("[type='file']");

		if(fileField.length > 0)
		{
			for(var i=0; i< fileField.length ; i++)
			{
				if($(fileField[i]).val() == "")
					$(fileField[i]).remove();
			}
		}
		return true;
	});
});

function confirmAction(title,message,redirect_url,callBack, icon_url)
{
	$("#ConfirmActionAlertMessage").html(message);
	$("#ConfirmActionAlertTitle").html(title);
	$('#ConfirmActionAlert').removeClass('modal-lg');
	$('#ConfirmActionAlert').modal('show');

	if ((icon_url != 'undefined') && (icon_url != '')) {
		$("#ConfirmActionAlertIcon").attr('href', icon_url);
	}
	
	// Remove previous event handlers
	$("#ConfirmActionMessageAction").off("click");
	$("#ConfirmActionMessageClose").off("click");

	$("#ConfirmActionMessageClose").click(function(){
		$('#ConfirmActionAlert').modal('hide')
	});

	if(redirect_url != null)
	{
		$("#ConfirmActionMessageAction").click(function(e){
			window.location.href= redirect_url;
		});
	}
	else
	{
		$("#ConfirmActionMessageAction").click(function(e){
			$('#ConfirmActionAlert').modal('hide');
			if(callBack != null)
				callBack();
		});
	}
	//event.preventDefault(message);
}
function confirmActionEncoded(message,redirect_url,encode_part)
{
	var link;

	if(encode_part != "")
	{
		var encode_param 	= encode_part.substr(0,encode_part.indexOf("=")+1); // Get the request parameter.
		var encode_link 	= encode_part.substr(encode_part.indexOf("=")+1); // Get the request parameter value.

		link = redirect_url+encode_param+encodeURIComponent(encode_link);
	}
	else
		link = redirect_url;

	$.fn.dialog2.helpers.confirm(message, {
		confirm: function() { window.location.href= link; }
	});

	//event.preventDefault(message);
}
/*========================================= ANSWERING BLANKED QUESTIONS =================================================*/
function setDraggable($elements)
{
	// Make draggable
	$($elements).draggable({
		containment: '.question_area', // Dragging is allowed inside the parent element only
		revert: true, // Float back to the same position
		cursor:'move',
		scroll: true,
		create: function(){$(this).data('position',$(this).position())},
		start: function() {
			// Make revert to true since when the element is dropped, it becomes false
			$(this).draggable( 'option', 'revert', true );
		},
		drag: function(e, ui)
		{
			const scrollTopOffset = $(document).scrollTop();
			const draggingObjectTopOffset = ui.offset.top;

			if ( draggingObjectTopOffset < (scrollTopOffset + 50) ) {
				$(document).scrollTop(draggingObjectTopOffset - 80);
			}
		},
		refreshPositions: true
	});
}

function setDroppable($elements)
{
	// Make droppable
	$($elements).droppable({
		accept: '.dragable',
		drop: handleEvent,// Event which fires when the element is dropped
		over: setDroppableWidth,
		tolerance: "intersect"
	});
}


function initBlanks() {
	setDraggable($('.dragable'));
	setDroppable($('.droppable'));
}
function setDroppableWidth( event, ui )
{
	var dragger = ui.draggable; // Answer
	var target 	= $(this); // Blank

	if(!target.hasClass("multiple"))
	{
		if(target.hasClass("blank"))
			adjustBlankWidth(dragger,target);
	}
}
// Fires when a element is dropped
function handleEvent(event, ui)
{
	var dragger = ui.draggable;
	var target = $(this);

	//ui.draggable.addClass( 'correct' ); // Change the Element style after dragging has done
	/*dragger.draggable( 'disable' ); // Avoid dragging this element again
	target.droppable( 'disable' ); // Avoid any other droppings to this element*/

	var blank_elements = document.getElementById("question_task").getElementsByTagName("span"); // Get all the blanks inside div Element
	var answer_id = ui.draggable.attr('id');

	// Adjust the blank so it match the width of the answer
		//( If the target is a blank only OR it is dragged back to the answers position)
	/*if(target.html().indexOf("_") !== -1 || target.attr("data") === undefined)
		adjustBlankWidth(dragger,target);*/

	// If the blank is set for multiple dropings
	if(target.hasClass("multiple"))
	{
		var $clone = dragger.clone();

		target.append($clone.css({
			top: 0,
			left: 0,
			width: dragger.width()
		}));

		initBlanks();
		setAnswers(this,answer_id,true); // Send draggable and droppable to set the answer
		dragger.draggable( 'option', 'revert', false ); // Avoid the draggable from floating back to its previous position
		dragger.remove();
	}
	else
	{
		// If the blank is not filled
		if(target.attr("data") === undefined || target.attr("data") == "0")
		{
			var blank_number = parseInt($("#"+answer_id).attr("blank"));
			$(blank_elements[blank_number]).attr("data","0");

			/* If the answer is dragged back to the answers position, reinstate in its original postion*/
			if(target.attr("data") === undefined)
			{
				dragger.animate({top:0,left:0},{duration:600,easing:'easeOutBack'});
			}
			else
			{
				var topMove = target.position().top - dragger.data('position').top + (target.outerHeight(true) - dragger.outerHeight(true)) / 2;
				var leftMove= target.position().left - dragger.data('position').left + (target.outerWidth(true) - dragger.outerWidth(true)) / 2;
 
				// Center the selection inside the blank
				dragger.animate({top:topMove,left:leftMove},{duration:600,easing:'easeOutBack'});

				// Filled is considered only for blanks
				if(target.attr("data") == "0")
					target.attr("data","1"); // Record that the blank is filled
			}
			
			setAnswers(this,answer_id,false); // Send draggable and droppable to set the answer
			dragger.draggable( 'option', 'revert', false ); // Avoid the draggable from floating back to its previous position
		}
	}
}
// Adjust the blank so it match the width of the answer
function adjustBlankWidth(dragger,target)
{
	var answer_width 	= dragger.width();
	var blank_width		= target.width();
	var difference		= answer_width - blank_width;

	if(difference > 0)
	{
		target.data("width",target.width);
		target.width(answer_width);
	}
	
	//============================= Reset the blank if the answer is dragged back from it =============================
	var blanks 		= $("span.droppable");
	var blank_index = parseInt(dragger.attr("blank"));
	//var reset_width = "_______________";

	if($(blanks[blank_index]).html() != null && $(blanks[blank_index]).hasClass("blank"))
		$(blanks[blank_index]).width($(blanks[blank_index]).data("width"));
}
function setAnswers(blank_obj,answer_id,multiple_drops)
{
	var blanks_arr = document.getElementById("question_task").getElementsByTagName("span"); // Get all the blanks inside div Element
	
	// We need to get the index of the answer that is dragged.	
	var answer = $("#"+answer_id).data("index");
	
	// First delete the answer from answers if the element is dragged back to the answers container
	if($("#"+answer_id).attr("blank") !== undefined)
	{
		var blank_number = parseInt($("#"+answer_id).attr("blank"));

		if(multiple_drops)
		{
			var answers_string 	= answers[blank_number].toString();
			var answer_pos		= answers_string.indexOf(answer);
			var string = "";
			answer = answer.toString();

			if(answer_pos == 0)
			{
				if(answer.indexOf(answer+"::") >= 0)
					string = answers_string.replace(answer+"::","");
				else
					string = answers_string.replace(answer,"");
			}
			else
				string = answers_string.replace("::"+answer,"");

			answers[blank_number] = string;
		}
		else
			answers[blank_number] = null;
	}
	
	for(var i=0;i<blanks_arr.length;i++)
	{
		// If the dropped element and blank element are the same
		if(blanks_arr[i] == blank_obj)
		{
			if(multiple_drops)
			{
				var answers_string = answers[i];
				
				// Answers string "0" means it has a value. So, other values must be appended to it
				if(answers_string !== 0 && (answers_string == null || answers_string == ""))
					answers_string = answer;
				else
					answers_string += "::" + answer;

				answers[i] = answers_string;
			}
			else
				answers[i] = answer; // Assign the answer to the blank id
			
			$("#"+answer_id).attr("blank",i); // This attribute decides which blank element it was put to when dragging it back to answers
			setAnswersForm();
			return;
		}
	}
	/*If the element is dragged back from blank to answers, delete the blank id*/
	$("#"+answer_id).removeAttr("blank");
	setAnswersForm();
}
/*================================ ANSWERING BLANKED QUESTIONS ENDS!! =========================================*/
function success(header,message){

	var body_layout = "	<h1>Success!</h1><div class='alert alert-success'><p>Records have been successfuly added</p></div><div class='form-actions'><input type='button' class='btn close-dialog' name='cancel' value='Ok'></div>";

	var head = $("#Success-message").html(body_layout);

	var head = $("#Success-message").find("h1")[0];
	var body = $("#Success-message").find(".alert-success")[0];

	$(head).html(header);
	$(body).html(message);

	$('#Success-message').dialog2('open');// Display dialog
}
function modalAlert(header,message,redirect_url)
{
	message = "<div class='alert alert-success'><svg class='feather'><use xlink:href='templates/assets/dist/icons/feather-sprite.svg#info'/></svg><div class='me-auto'>Success! "+message+"</div><button type='button' class='close' data-bs-dismiss='alert'><svg class='feather'><use xlink:href='templates/assets/dist/icons/feather-sprite.svg#x'/></svg></button></div>";

	$.fn.dialog2.helpers.alert(message, {
		title: header,
		close: function() {
			if(redirect_url != null)
				document.location.href=redirect_url;
		}
	});
	event.preventDefault();
}
function modalError(header,message,redirect_url)
{
	message = "<div class='alert alert-danger'><svg class='feather'><use xlink:href='templates/assets/dist/icons/feather-sprite.svg#alert-octagon'/></svg><div class='me-auto'>Error Found! "+message+"</div><button type='button' class='close' data-bs-dismiss='alert'><svg class='feather'><use xlink:href='templates/assets/dist/icons/feather-sprite.svg#x'/></svg></button></div>";
			

	$.fn.dialog2.helpers.alert(message, {
		title: header,
		close: function() {
			if(redirect_url != null)
				document.location.href=redirect_url;
		}
	});
	event.preventDefault();
}
/*Set length of the rankbar according to the length of the container*/
function setRankBar()
{
	var margin = 26; // Margin from middle to the both sides
	var page_top_width 	= $(".user_page_top").width();
	var left_width 		= $("#left").width();
	var right_width 	= $("#right").width();

	var sides_percentage	= (left_width+right_width+margin)*100/page_top_width;
	var barWidth 			= 100-sides_percentage;

	$("#middle").css("width",(barWidth-1)+"%");
}
/**
 * Load table content using server side script
 * @param String url Location of the file to load the data
*/
function servDataTable(dataUrl,sortUrl)
{
	if(sortUrl == null || sortUrl == "")
	{
		$('.data_table').dataTable( {
			"sPaginationType": "bootstrap",
			"oLanguage": {
				 "sLengthMenu": "Display <select>'+'<option value=\"10\">10</option>'+'<option value=\"20\">20</option>'+'<option value=\"30\">30</option>'+'<option value=\"40\">40</option>'+'<option value=\"50\">50</option>'+'<option value=\"-1\">All</option>'+'</select> records"
			},
			"processing": true,
			"serverSide": true,
			"ajax": dataUrl,
			"fnDrawCallback": function(){
				/**
				 * Reload dialog2 once the data is loaded into the table
				*/
				$(document).controls();
			}
		} );
	}
	else
	{
		$table = $('.data_table').dataTable( {
			"sPaginationType": "bootstrap",
			"oLanguage": {
				 "sLengthMenu": "Display <select>'+'<option value=\"10\">10</option>'+'<option value=\"20\">20</option>'+'<option value=\"30\">30</option>'+'<option value=\"40\">40</option>'+'<option value=\"50\">50</option>'+'<option value=\"-1\">All</option>'+'</select> records"
			},
			"processing": true,
			"serverSide": true,
			"ajax": dataUrl,
			"fnDrawCallback": function( oSettings ) {
				/**
				 * ============= After the data has been loaded in to the table from the server side ==========
				 * Add table rowReordering
				 * Reload dialog2
				*/
				$(document).controls();
			},
			"fnInitComplete": function(oSettings, json) {
				$table.rowReordering({
					sURL:sortUrl, // Let the server page know if order is changed
					sRequestType: "GET",
					fnAlert: function(message) {
						modalError("Server Error",message,null); // Error message if an server error occurred during the process
					}
				});
			}
		} );
		$.extend( $.fn.dataTableExt.oStdClasses, {
			"sWrapper": "dataTables_wrapper form-inline"
		} );
	}
}
function dataTable(url)
{
	var $tables = new Array();

	if(url == null || url == "")
	{
		$('.data_table').each(function(index){
			var $table =  $(this).dataTable( {
				"sPaginationType": "bootstrap",
				"oLanguage": {
					 "sLengthMenu": "Display <select>'+'<option value=\"10\">10</option>'+'<option value=\"20\">20</option>'+'<option value=\"30\">30</option>'+'<option value=\"40\">40</option>'+'<option value=\"50\">50</option>'+'<option value=\"-1\">All</option>'+'</select> records"
				},
				"fnDrawCallback": function( oSettings ) {
					console.log(oSettings);
				}



			} );

			$tables.push($table);
		});


		/*$table = $('.data_table').dataTable( {
			"sPaginationType": "bootstrap",
			"oLanguage": {
				 "sLengthMenu": "Display <select>'+'<option value=\"10\">10</option>'+'<option value=\"20\">20</option>'+'<option value=\"30\">30</option>'+'<option value=\"40\">40</option>'+'<option value=\"50\">50</option>'+'<option value=\"-1\">All</option>'+'</select> records"
			}
		} );*/
	}
	else
	{

		$('.data_table').each(function(index){
			var $table =  $('.data_table').dataTable( {
				"sPaginationType": "bootstrap",
				"oLanguage": {
					 "sLengthMenu": "Display <select>'+'<option value=\"10\">10</option>'+'<option value=\"20\">20</option>'+'<option value=\"30\">30</option>'+'<option value=\"40\">40</option>'+'<option value=\"50\">50</option>'+'<option value=\"-1\">All</option>'+'</select> records"
				}/* ,
				"fnDrawCallback": function( oSettings ) {
					let iPage = 0;

					if(oSettings._iDisplayLength !== -1)
					{
						iPage = Math.ceil( oSettings._iDisplayStart / oSettings._iDisplayLength );
					}

					window.history.pushState('', '', window.location.href+'&iPage='+iPage);
				} */
			} ).rowReordering({
				sURL:url, // Let the server page know if order is changed
				sRequestType: "GET",
				fnAlert: function(message) {
					modalError("Server Error",message,null); // Error message if an server error occured during the process
				}
			});

			$tables.push($table);
		});

		/*$table = $('.data_table').dataTable( {
			"sPaginationType": "bootstrap",
			"oLanguage": {
				 "sLengthMenu": "Display <select>'+'<option value=\"10\">10</option>'+'<option value=\"20\">20</option>'+'<option value=\"30\">30</option>'+'<option value=\"40\">40</option>'+'<option value=\"50\">50</option>'+'<option value=\"-1\">All</option>'+'</select> records"
			}
		} ).rowReordering({
			sURL:url, // Let the server page know if order is changed
			sRequestType: "GET",
			fnAlert: function(message) {
				modalError("Server Error",message,null); // Error message if an server error occured during the process
			}
		});*/

		$.extend( $.fn.dataTableExt.oStdClasses, {
			"sWrapper": "dataTables_wrapper form-inline"
		} );
	}
	return $tables;
}

/*================================================= SET THE PAGE TITLE WIDTH =================================================*/
function setTopHeading()
{
	var options_width 	= $(".small").width(); // Width taken by option icons
	var total_width		= $("#PageWrapper").width(); // Page width
	var title_width		= $(".dashboard_top .heading").width(); // Total width of page heading
	var ava_width		= total_width - options_width; // Available width for the title

	if(title_width > ava_width)
	{
		var font_size 			= parseInt($(".dashboard_top .heading").css("font-size"),10);// Size of a font
		var remv_font_amount	= Math.round((title_width - ava_width) / font_size); // Deleting fonts amount

		var title 		= $(".dashboard_top .heading").html();
		var new_title 	= title.substr(0,title.length - (remv_font_amount+8)) + "...";

		$(".dashboard_top .heading").html(new_title);
	}
}
/********** Adjust a sentence inside a container by cutting off extra letters ***************/
// "container" is the statement container array
// "ignore" is extra width to be ignored (Margins)
// If "checkOthers" is set to true, calculates the width of the elements which are in the same level as the element
// If "parent" is null, automatically picks the parent of the element
function ellipsis(container,parent,ignore,checkOthers)
{
	var parentWidth;

	$(container).width(0); // Remove any default width.

	for(var i=0;i<container.length;i++)
	{
		var ignoreWidth = ignore;

		if(parent == null)
			parentWidth = $(container[i]).parent().width();
		else
			parentWidth = $(parent).width();

		if(checkOthers)
		{
			$(container[i]).parent().children().not($(container[i])).each(function(){

				ignoreWidth += parseInt($(this).outerWidth()); // Calculate the total width of the other elements in the same line as this element
			});
		}
		var css = {
			width: (parentWidth - ignoreWidth),
			"white-space": "nowrap",
			"overflow" : "hidden",
			"text-overflow": "ellipsis" // Add ... to the end of the statement
		};
		$(container[i]).css(css);
		$(container[i]).children().css(css); // Apply ellipsis to it's children
	}
}
// Function to encode correct answers (Used in puzzles)
function encodeAnswer( answersAmount )
{
	// Do some encoding before adding it to the answers
	$("#answers").val(answersAmount); // Store the number of correct answers
}
function setGuideUrl( url )
{
	$("#adminGuide").click(function(){
		openGuideWindow( url );
	});
}
// Read an selected image for upload field and display a preview in a given target
function readURL(input,target) {
	if (input.files && input.files[0]) {
		var reader = new FileReader();
		reader.onload = function (e) {
			$(target).attr('src', e.target.result);
		}
		reader.readAsDataURL(input.files[0]);
	}
}

function setHeight()
{
	var windowheight=$(window).height();
	var footerheight = $('.footer').height();
	var headerheight = $('.background_image2').offset();

	//alert(windowheight);
	if(headerheight != null)
	{
		// If the slider is not hidden
		if($('.background_image2').is(":visible"))
		{
			var bknew = windowheight - (footerheight + headerheight.top+parseInt($('.background_image2').css("padding-top")));
			$('.background_image2').css('height',bknew);
			$('.background_image2 img').css('max-height',bknew);
		}
		else
		{
			var bknew = windowheight - footerheight;

			$('#container').css({
				'min-height': bknew,
				'max-height': bknew
			});
		}
	}
	else
	{
		var bknew = windowheight - ($("#header").height()+footerheight+parseInt($('#PageWrapper').css('padding-bottom')))+1;
		$('#PageWrapper').css('min-height',bknew);
	}
	// Hide footer in mobile view
	if($(window).width() < 360)
		$(".footer").hide();
	else
		$(".footer").show();
}
function AjaxFormValidate(link,$form)
{
	$.ajax({
		type: "POST",
		url:link,
		data: $form.serialize(),
		success: function(response) {
			var obj = false;

			if(response != "")
			{
				try
				{
					obj = JSON.parse(response);
				}
				catch (e) {
					console.log("JSON parse exception. Response: ");
					console.log(response);
					obj = false;
				}
			}
	
			if(obj)
			{
				// Remove success messages if any
				if($(".alert.alert-success").length > 0)
					$(".alert.alert-success").remove();

				var err_text = '<div class="alert alert-danger"><svg class="feather"><use xlink:href="templates/assets/dist/icons/feather-sprite.svg#alert-octagon"/></svg><div class="me-auto pt-1">Error Found! '+obj+'</div><button type="button" class="btn-close" data-bs-dismiss="alert"><svg class="feather"><use xlink:href="templates/assets/dist/icons/feather-sprite.svg#x"/></svg></button></div>';
				

				$("#errors").html(err_text);
			}
			else
				$form.submit();
		},
		error: function(jqXHR, textStatus, errorThrown) {
			console.log(errorThrown);
		}
	});
}
// Function to add blank when add blank button is clicked
function insertSymbol(ID,text) {
	var txtarea = $("#"+ID)[0];
	var scrollPos = txtarea.scrollTop;
	var strPos = 0;
	var br = ((txtarea.selectionStart || txtarea.selectionStart == '0') ?
	"ff" : (document.selection ? "ie" : false ) );
	if (br == "ie") {
		txtarea.focus();
		var range = document.selection.createRange();
		range.moveStart ('character', -txtarea.value.length);
		strPos = range.text.length;
	}
	else if (br == "ff") strPos = txtarea.selectionStart;

	var front = (txtarea.value).substring(0,strPos);
	var back = (txtarea.value).substring(strPos,txtarea.value.length);
	txtarea.value=front+text+back;
	strPos = strPos + text.length;
	if (br == "ie") {
		txtarea.focus();
		var range = document.selection.createRange();
		range.moveStart ('character', -txtarea.value.length);
		range.moveStart ('character', strPos);
		range.moveEnd ('character', 0);
		range.select();
	}
	else if (br == "ff") {
		txtarea.selectionStart = strPos;
		txtarea.selectionEnd = strPos;
		txtarea.focus();
	}
	txtarea.scrollTop = scrollPos;
}

/**
 * Function which will submit the selected values of a datatables instance regardless of the pager
 * @param $table Datatable instance
 * @param columnIndex Column index which is containing the checbox/radio button
 * @param selector Checkbox/radio button selector
 * @param inputName Hidden input name which will be submitted with the checked values
 */
function dataTableCheckboxes($table,columnIndex,selector,inputName,formSelector)
{
	var values 	= {};
	
	if(formSelector !== undefined)
		var $form 	= $(formSelector);
	else
		var $form 	= $(selector).parents("form");

	var $hiddenElement = $form.find('[name="'+inputName+'"]');
	
	// If the input is already added to the form
	if($hiddenElement.length > 0)
	{
		values = JSON.parse($hiddenElement.val());
	}
	else
	{
		$.each( $table.fnGetData(), function(i, row){
			
			var $checkbox = $(row[columnIndex]);
			
			if($checkbox.is(":checked") && $checkbox.hasClass(selector.replace(".","")))
				values[$checkbox.val()] = $checkbox.val();
			else
			{
				// If the checkbox has a wrapper, we will locate the checkbox inside it, and get its value 
				$childCheckbox = $checkbox.find(selector);
				
				if($childCheckbox.length > 0 && $childCheckbox.is(":checked"))
					values[$childCheckbox.val()] = $childCheckbox.val();
			}
	    });
		
		$hiddenElement = $("<input type='hidden' name='"+inputName+"'>");
		$form.append($hiddenElement);
		$hiddenElement.val(JSON.stringify(values));
	}
	
	$(document).on("change",selector,function(){
		var value = $(this).val();

		/**
		 * $(this).data("checked") Uses for custom checked checkboxed (Which are used as radio buttons like in moderations)
		 */

		if($(this).is(":checked") && $(this).data("checked") == undefined)
			values[value]  = value;
		else
			delete values[value];

		if( $(this).data("checked") == false)
			 $(this).removeData("checked");

		$hiddenElement.val(JSON.stringify(values));
	});
}

/**
 * Function which will submit ONLY the changed values of a datatables instance regardless of the pager
 * @param $table Datatable instance
 * @param columnIndex Column index which is containing the checbox/radio button
 * @param selector Checkbox/radio button selector
 * @param checkInputName Hidden input name which will be submitted with the checked values
 * @param uncheckInputName Hidden input name which will be submitted with the un-checked values
 */
function dataTableStatusCheckboxes($table,columnIndex,selector,checkInputName,uncheckInputName,formSelector)
{
	var checkedValues 	= {};
	var unCheckedValues 	= {};
	
	if(formSelector !== undefined)
		var $form 	= $(formSelector);
	else
		var $form 	= $(selector).parents("form");
    
	var $checkHiddenElement = $form.find('[name="'+checkInputName+'"]');
	var $unCheckHiddenElement = $form.find('[name="'+uncheckInputName+'"]');
	
	// If the input is already added to the form
	if($checkHiddenElement.length == 0)
	{
		$checkHiddenElement = $("<input type='hidden' name='"+checkInputName+"' value='{}'>");
		$form.append($checkHiddenElement);
		
		$unCheckHiddenElement = $("<input type='hidden' name='"+uncheckInputName+"' value='{}'>");
		$form.append($unCheckHiddenElement);
	}
	
	$(document).on("change",selector,function(){
		var value = $(this).val();

		/**
		 * $(this).data("checked") Uses for custom checked checkboxed (Which are used as radio buttons like in moderations)
		 */

		if($(this).is(":checked"))
		{
			checkedValues = JSON.parse($checkHiddenElement.val());
			checkedValues[value]  = value;
			delete unCheckedValues[value];
		}
		else
		{
			unCheckedValues = JSON.parse($unCheckHiddenElement.val());
			unCheckedValues[value]  = value;
			delete checkedValues[value];
		}

		if( $(this).data("checked") == false)
			 $(this).removeData("checked");

		$checkHiddenElement.val(JSON.stringify(checkedValues));
		$unCheckHiddenElement.val(JSON.stringify(unCheckedValues));
	});
}

// Count the number of values in a object
function objectCount(valuesObj)
{
	var counter=0;
	for(var val in valuesObj)
	{
		counter++;
	}

	return counter;
}

function showErrors($wrapper,errors)
{
	$wrapper.html('<div class="alert alert-danger"><svg class="feather"><use xlink:href="templates/assets/dist/icons/feather-sprite.svg#alert-octagon"/></svg><div class="me-auto pt-1">Error Found! '+errors+'</div><button type="button" class="btn-close" data-bs-dismiss="alert"><svg class="feather"><use xlink:href="templates/assets/dist/icons/feather-sprite.svg#x"/></svg></button></div>');
}

/**+++++++++++++++++++++++++++++++++++++++++++++++++++++++ CUSTOM MODULES ++++++++++++++++++++++++++++++++++++++++++++**/

/**
 * Module for ajax POST/GET
 * @param url request url
 * @param method POST/GET
 * @param onComplete function to be called once the request is successfully completed
 */
var AjaxRequest = (function(url,method,onComplete){
	
	var obj = {};
	
	/**
	 * Make AJAX request
	 * @param data Additional data to be sent. Must be serialized
	 */ 
	function makeRequest(data, object)
	{
		var ajaxVars = {
			url : url,
			type: method,
			data : data,
			success: function(data, textStatus, jqXHR)
			{
				onComplete(data);
			},
			error: function (jqXHR, textStatus, errorThrown)
			{

			}
		};

		if (object !== "undefined") {
			if (object) {
				ajaxVars['processData'] = false;
				ajaxVars['contentType'] = false;
				ajaxVars['dataType'] = 'json';
			}
		}

		$.ajax(ajaxVars);
	}
	
	/**
	 * Initialize the object
	 * @param data Additional data to be sent via ajax. Must be serialized
	 */
	obj.init = function(data, object){
		makeRequest(data, object);
	}
	
	return obj;
	
});

/**
 * Voting for Discussions and Comments
 */
var Vote = (function(){
	
	var obj = {};
	
	var $upvoteDiscusstion;
	
	var upvoteOncomplete = function(result){
		var obj = JSON.parse(result);
		var buttonText = null;
		var votesCount = null;
		var votesHtml = null;

		if(obj.errors == undefined)
		{
			if($upvoteDiscusstion.data("state") == "down")
			{
				buttonText = 'Up Vote<span class="numVotes">1</span>';
				$upvoteDiscusstion.data("state","up");
				$upvoteDiscusstion.attr("href",$upvoteDiscusstion.attr("href").replace("&down=1",""));
			}
			else
			{
				buttonText = 'Down Vote<span class="numVotes">1</span>';
				$upvoteDiscusstion.data("state","down");
				$upvoteDiscusstion.attr("href",$upvoteDiscusstion.attr("href")+"&down=1");
			}
			
			votesCount = obj.upvotes;
			votesHtml = obj.votes_html;
			
			$(document).controls(); // Make sure the user list is loaded inside modal
		}
		else
		{
			showErrors($("#MessageWrapper"),obj.errors);
		}
		
		$upvoteDiscusstion.button('reset');
		
		if(buttonText != null)
		{
			$upvoteDiscusstion.html(buttonText);
			$upvoteDiscusstion.children(".numVotes").html(votesCount);
			$upvoteDiscusstion.parent().children(".votesHtml").html(votesHtml);
		}
	}

	/**
	 * Initialize the object
	 * @param postUrl Server side url
	 * @param indentifier class/id of the element which is clicked 
	 */
	obj.init = function(postUrl,indentifier){
		$(document).on("click",indentifier,function(e){
			$upvoteDiscusstion = $(this);
			e.preventDefault();
			
			$(this).button('loading');
			
			new AjaxRequest(postUrl+$(this).attr("href"),"POST",upvoteOncomplete).init();
		});
	}
	
	return obj;
});

/**
 * Checking checkboxes inside tables
 */
var Check = (function(){

	var obj = {};
	var allSelected;
	var parentClass;
	var childClass;
	var $table;
	var checkboxColumnIndex;
	var inputName;
	
	var values 	= {};
	var $form;
	var $hiddenElement;
	
	// Update form input with new values
	var updateForm = function(){
		$hiddenElement.val(JSON.stringify(values));
	}
	
	// Update table with current action
	var updateTable = function($childElement)
	{
		var parentChecked = $(parentClass).prop('checked');
		
		var tickedChildCount = 0;
		var childCount		 = 0;
		
		$(childClass, $table.fnGetNodes()).each(function () {
			
			if($childElement == null){
				// If the parent checkbox is clicked
				if(parentChecked)
				{
					$(this).prop('checked', true);
					values[$(this).val()] = $(this).val(); // Add each value to our object
				}
				else
					$(this).prop('checked', false);
			}

			if($(this).prop('checked'))
				tickedChildCount++;
			
			childCount++;
			
		}); 
		
		
		if($childElement != null){
			if($childElement.prop('checked')){
				
				if(!parentChecked && childCount == tickedChildCount)
					$(parentClass).prop('checked',true);
				
				values[$childElement.val()] = $childElement.val(); // Add each value to our object
				
			}
			else{
				if(parentChecked)
					$(parentClass).prop('checked',false);
				
				delete values[$childElement.val()];
			}
		}
		else if(!parentChecked){
			
			values = {}; // Remove all from our object
		}
		//$table.fnDraw();
	}
	
	/**
	 * Select or Unselect children element based on parent checked/not-checked status
	 */
	var parentClicked = function(){
		updateTable(null);
		updateForm();
	}
	
	/**
	 * Select or Unselect parent element based on children checked/not-checked status
	 */
	var childClicked = function($childElement){
		updateTable($childElement);
		updateForm();
	}
	
	var bindToEvents = function(){

		/*$table.on( 'draw.dt', function ( e, settings, len ) {
			parentClicked(parentClass,childClass);
		} );*/
		
		// Append hidden input to the form
		
		
		// If the parent checkbox is clicked, check the child checboxes
		$(document).on("click",parentClass,function(e){
			parentClicked();
			
		});
		
		// If the child checkbox is clicked, check/uncheck parent checkbox
		$(document).on("click",childClass,function(e){
			childClicked($(this));
			
		});
	}
	
	var saveInitialValues = function(){
		
		$.each( $table.fnGetData(), function(i, row){
			var $checkbox = $(row[checkboxColumnIndex]);

			if($checkbox.is(":checked"))
				values[$checkbox.val()] = $checkbox.val();
	    });
		updateForm();
	}
	
	var addHiddenInput = function(){
		$form 	= $(parentClass).parents("form");
		console.log(inputName);
		$hiddenElement = $("<input type='hidden' name='"+inputName+"'>");
		$form.append($hiddenElement);
		
	}
	
	/**
	 * Initialize the object
	 * @param parentClass parent indentifier
	 * @param childClass Child identifier
	 * @param $t datatable instance
	 */
	obj.init = function(parent,child,$t,colIndex){
		
		parentClass = parent;
		childClass = child;
		$table = $t;
		checkboxColumnIndex = colIndex;
		inputName = "check_values";
		addHiddenInput();
		saveInitialValues();
		bindToEvents();
	}
	
	return obj;
});


function setVideoPlayer()
{
	var players = new Array();
	// Count the number of videos in the page
	$(".video-js").each(function(index){
		$(this).attr("id","videoPlayer"+index);
		
		var width 		= 600;
		var height		= 350;
		var player		= {
			"player": null,
			"media_functions": new Array()
		};

		if($(this).parent().data("width") != "" && $(this).parent().data("width") != "100%")
			width 		= parseInt($(this).parent().data("width"));
		else
			var width	= $(this).parent().parent().width();
		if($(this).parent().data("height") != "")
			height 		= parseInt($(this).parent().data("height"));

		var myPlayer 	= videojs("videoPlayer"+index);


		if($(this).parent().data("media_functions") !== undefined && $(this).parent().data("media_functions") != null)
			player.media_functions = $(this).parent().data("media_functions").split(",");

		myPlayer.width(width);
		myPlayer.height(height);
		
		//$(myPlayer).enterFullScreen(); 
		//myPlayer.Pa(); // Preload, so it will allow to go full screen while playing the video

		player.player = myPlayer;
		players.push(player);
	});

	return players;
}
function setAudioPlayer()
{
	if($("audio").length > 0)
	{
		audiojs.events.ready(function() {
			$("audio").each(function(index){

				var audio 	= audiojs.create($(this));
				var styles	= $(this).data("styles");

				if(styles != "")
				{
					 // Mark each user style as important
					var re 		= new RegExp(';', 'g');
					styles 		= styles.replace(re, ' !important;');
				
					audiojs.helpers.injectCss(audio[0],".audiojs{"+styles+"}");
					var scrubber_width = $(".audiojs").outerWidth() - ($(".play-pause").outerWidth() + $(".time").outerWidth()+50);
					audiojs.helpers.injectCss(audio[0],".scrubber{width: "+scrubber_width+"px !important;}");
				}
			});
		});
		/*audiojs.events.ready(function() {
			audiojs.createAll();
		}); // invoke audio player code**/
	}
}

function refreshIframeContent(iframe)
{
	iframe.src=iframe.src;
}

//remove right click inside iframe
//document.addEventListener('contextmenu', event => event.preventDefault());